<template>
  <div class="company_info">
    <!-- 公司资料--VUE -->
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="130px"
    >
      <el-collapse v-model="activeNames" >
        <el-collapse-item title="基础信息" name="1">
          <p class=" text-warning pl-48">*标记为必填项</p>
          <div class="w-1/2">
            <el-form-item class="w-full" label="公司类型" prop="type">
              <el-radio-group v-model="ruleForm.type">
                <el-radio :label="'dealer'">经销商</el-radio>
                <el-radio :label="'manufacturer'">生产商</el-radio>
                <el-radio :label="'garage'">修理厂</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="w-full" label="公司名称" prop="name">
              <el-input
                v-model="ruleForm.name"
                type="text"
                maxlength="50"
                placeholder="最多50个字"
              ></el-input>
            </el-form-item>
            <el-form-item class="w-full" label="联系人" prop="contacts">
              <el-input
                v-model="ruleForm.contacts"
                type="text"
                maxlength="20"
                placeholder="请输入联系人姓名"
              ></el-input>
            </el-form-item>
            <el-form-item class="w-full" label="联系电话" prop="phone">
              <el-input
                v-model="ruleForm.phone"
                maxlength="11"
                type="text"
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="w-full" label="所在地区" prop="areaId">
              <SelectArea
                style="width: 100%"
                :areaData="ruleForm.areaIds"
                @change="areaChange"
              />
            </el-form-item>
            <el-form-item class="w-full" label="详细地址" prop="address">
              <el-input
                v-model="ruleForm.address"
                type="text"
                maxlength="50"
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>
            <el-form-item class="w-full" label="所在汽配城" prop="address">
              <el-select
                v-model="ruleForm.autoPartsCity"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in autoPartsCitys"
                  :key="item.name+item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              class="w-full"
              label="统一社会信用代码"
              prop="bizLicenceCode"
            >
              <el-input
                v-model="ruleForm.bizLicenceCode"
                type="text"
                maxlength="18"
                placeholder="请输入统一社会信用代码"
              >
              </el-input>
            </el-form-item>
            <el-form-item class="w-full" label="营业执照" prop="licencesImage">
              <div class="upload_file">
                <UploadFileImg
                  :maxSize="1"
                  listType="picture-card"
                  :limit="1"
                  :fileList="imgList"
                  :isMultiple="false"
                  :showFileList="true"
                  :hideFlag="true"
                  @getFileList="(fileList) => getImgFileList(fileList)"
                />
              </div>
            </el-form-item>
            <el-form-item class="w-full" label="公司简介" prop="description">
              <el-input
                v-model="ruleForm.description"
                type="text"
                maxlength="200"
                placeholder="请输入公司简介"
              ></el-input>
            </el-form-item>
          </div>
        </el-collapse-item>
        <el-collapse-item title="经营信息" name="2">
          <div class="w-1/2">
            <el-form-item prop="mobile" class="w-full" label="公司logo">
              <div class="upload_file">
                <UploadFileImg
                  :maxSize="1"
                  listType="picture-card"
                  :limit="1"
                  :fileList="companyLogoList"
                  :isMultiple="false"
                  :showFileList="true"
                  :hideFlag="true"
                  @getFileList="
                    (fileList) => getFileList(fileList, 'companyLogoList')
                  "
                />
              </div>
            </el-form-item>
            <el-form-item class="w-full" label="公司图片">
              <div class="upload_file">
                <UploadFileImg
                  :maxSize="4"
                  listType="picture-card"
                  :limit="4"
                  :fileList="imagesList"
                  :isMultiple="false"
                  :showFileList="true"
                  :hideFlag="true"
                  @getFileList="
                    (fileList) => getFileList(fileList, 'imagesList')
                  "
                />
              </div>
            </el-form-item>
            <el-form-item class="w-full" label="主营配件类别">
              <el-cascader
                v-model="ruleForm.categories"
                :options="categories"
                :props="{
                  multiple: true,
                  label: 'name',
                  value: 'name',
                }"
                :show-all-levels="false"
                clearable
                class="w-full"
              ></el-cascader>
            </el-form-item>
            <el-form-item class="w-full" label="主营车型品牌">
              <el-select
                v-model="ruleForm.vehBrands"
                :remote-method="remoteVehBrands"
                filterable
                multiple
                clearable
              >
                <el-option
                  v-for="(item, index) in vehBrands"
                  :key="index"
                  :label="item.name"
                  :value="item.name.toString()"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="w-full" label="主营配件品牌">
              <el-select
                v-model="ruleForm.brands"
                :remote-method="remoteBrands"
                filterable
                multiple
                clearable
              >
                <el-option
                  v-for="(item, index) in brands"
                  :key="index"
                  :label="item.name"
                  :value="item.name.toString()"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="w-full" label="公司网站" prop="website">
              <el-input
                v-model="ruleForm.website"
                type="text"
                maxlength="50"
                placeholder="最多50个字"
              >
              </el-input>
            </el-form-item>
            <el-form-item class="w-full" label="公司公众号">
              <UploadFileImg
                :maxSize="1"
                listType="picture-card"
                :limit="1"
                :fileList="wechatOffiCccountImageList"
                :isMultiple="false"
                :showFileList="true"
                :hideFlag="true"
                @getFileList="
                  (fileList) =>
                    getFileList(fileList, 'wechatOffiCccountImageList')
                "
              />
            </el-form-item>
          </div>
        </el-collapse-item>
        <el-collapse-item title="联系方式" name="3">
          <div class="w-1/2">
            <el-form-item class="w-full" label="联系人1" prop="phone1">
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-input
                    v-model="ruleForm.contacts1"
                    type="text"
                    maxlength="50"
                    placeholder="最多50个字"
                  >
                  </el-input>
                </el-col>
                <el-col :span="15">
                  <el-input
                    v-model="ruleForm.phone1"
                    type="text"
                    maxlength="11"
                    placeholder="请输入手机号"
                  >
                  </el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item class="w-full" label="联系人2" prop="phone2">
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-input
                    v-model="ruleForm.contacts2"
                    type="text"
                    maxlength="50"
                    placeholder="最多50个字"
                  >
                  </el-input>
                </el-col>
                <el-col :span="15">
                  <el-input
                    v-model="ruleForm.phone2"
                    type="text"
                    maxlength="11"
                    placeholder="请输入手机号"
                  >
                  </el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item class="w-full" label="联系人3" prop="phone3">
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-input
                    v-model="ruleForm.contacts3"
                    type="text"
                    maxlength="50"
                    placeholder="最多50个字"
                  >
                  </el-input>
                </el-col>
                <el-col :span="15">
                  <el-input
                    v-model="ruleForm.phone3"
                    type="text"
                    maxlength="11"
                    placeholder="请输入手机号"
                  >
                  </el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item class="w-full" label="微信">
              <el-input
                v-model="ruleForm.wechat"
                type="text"
                maxlength="50"
                placeholder="最多50个字"
              >
              </el-input>
            </el-form-item>
            <el-form-item class="w-full" label="微信名片">
              <div class="upload_file">
                <UploadFileImg
                  :maxSize="4"
                  listType="picture-card"
                  :limit="4"
                  :fileList="wechatImages"
                  :isMultiple="false"
                  :showFileList="true"
                  :hideFlag="true"
                  @getFileList="
                    (fileList) => getFileList(fileList, 'wechatImages')
                  "
                />
              </div>
            </el-form-item>
            <el-form-item class="w-full" label="QQ">
              <el-input
                v-model="ruleForm.qq"
                type="text"
                maxlength="50"
                placeholder="最多50个字"
              >
              </el-input>
            </el-form-item>
            <el-form-item class="w-full" label="QQ名片">
              <div class="upload_file">
                <UploadFileImg
                  :maxSize="4"
                  listType="picture-card"
                  :limit="4"
                  :fileList="qqImages"
                  :isMultiple="false"
                  :showFileList="true"
                  :hideFlag="true"
                  @getFileList="(fileList) => getFileList(fileList, 'qqImages')"
                />
              </div>
            </el-form-item>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div class="footer_btn">
        <div class="button-div flex justify-center space-x-16">
          <!-- <el-button
            type="primary"
            size="small"
            @click="changeStatus(STATUS.BEENTAKED)"
            >加入其他公司</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="changeStatus(STATUS.COMINFO1)"
            >认证新公司</el-button
          > -->
          <el-button type="primary" size="small" @click="submitForm('ruleForm')"
            >保存</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { STATUS } from "../../../store/index";
import UploadFileImg from "@/components/Upload/UploadFileImg";
import { getCategories, getBrands, getVehBrands, getDict } from "@/api/basic";
import { getCompany, updateCompany } from "@/api/company";
import SelectArea from "@/components/selectArea/SelectArea.vue";
import {
  mobileValidate,
  mobileValidateNotRequired,
  bzCodeValidate,
} from "@/utils/validate";

export default {
  name: "companyInfo",
  components: {
    SelectArea,
    UploadFileImg,
  },
  data() {
    return {
      activeNames: ["1",'2','3'],
      STATUS,
      companyLogoList: [],
      imagesList: [],
      wechatOffiCccountImageList: [],
      wechatImages: [],
      imgList: [],
      qqImages: [],
      autoPartsCitys: [],
      ruleForm: {
        name: "",
        type: "dealer",
        contacts: "",
        phone: "",
        areaId: "",
        address: "",
        bizLicenceCode: "",
        licencesImage: "",
        description: "",
        website: "",
        wechatOffiCccount: "",
        contacts1: "",
        contacts2: "",
        contacts3: "",
        phone1: "",
        phone2: "",
        phone3: "",
        wechat: "",
        wechatImages: [],
        qqImages: [],
      },
      rules: {
        phone: [{ validator: mobileValidate, trigger: "blur" }],
        bizLicenceCode: [{ validator: bzCodeValidate, trigger: "blur" }],
        type: [{ required: true, trigger: "blur", message: "请选择公司类型" }],
        name: [{ required: true, trigger: "blur", message: "请输入公司名称" }],
        contacts: [
          { required: true, trigger: "blur", message: "请输入联系人" },
        ],
        phone: [{ required: true, trigger: "blur", message: "请输入联系电话" }],
        areaId: [
          { required: true, trigger: "blur", message: "请选择所在地区" },
        ],
        phone1: [{ validator: mobileValidateNotRequired, trigger: "blur" }],
        phone2: [{ validator: mobileValidateNotRequired, trigger: "blur" }],
        phone3: [{ validator: mobileValidateNotRequired, trigger: "blur" }],
      },
      categories: [],
      brands: [],
      vehBrands: [],
    };
  },
  watch: {
    companyLogoList: {
      handler: function(newVal) {
        this.ruleForm.logoImage =
          newVal && newVal.length > 0 ? newVal[0].origin || newVal[0] : "";
      },
      deep: true,
    },
    wechatOffiCccountImageList: {
      handler: function(newVal) {
        this.ruleForm.wechatOffiCccountImage =
          newVal && newVal.length > 0 ? newVal[0].origin : "";
      },
      deep: true,
    },
    imgList: {
      handler: function(newVal) {
        this.ruleForm.licencesImage =
          newVal && newVal.length > 0 ? newVal[0].origin || newVal[0] : "";
      },
      deep: true,
    },
    imagesList: {
      handler: function(newVal) {
        this.ruleForm.images =
          newVal && newVal.length > 0
            ? newVal.map((item) => item.origin || item)
            : [];
      },
      deep: true,
    },
    wechatImages: {
      handler: function(newVal) {
        this.ruleForm.wechatImages =
          newVal && newVal.length > 0
            ? newVal.map((item) => item.origin || item)
            : [];
      },
      deep: true,
    },
    qqImages: {
      handler: function(newVal) {
        this.ruleForm.qqImages =
          newVal && newVal.length > 0
            ? newVal.map((item) => item.origin || item)
            : [];
      },
      deep: true,
    },
  },
  activated() {
    this.$store.dispatch("getProfile");
    this.init();
  },
  mounted() {
    this.$store.dispatch("getProfile");
    this.init();
    this.getDict();
    this.remoteCategories();
    this.remoteBrands();
    this.remoteVehBrands();
  },
  methods: {
    init() {
      getCompany().then((res) => {
        this.ruleForm = {
          ...res,
          areaIds: res.areaIds || []
        };
        const {
          licencesImage,
          logoImage,
          wechatOffiCccountImage,
          images,
          wechatImages,
          qqImages,
        } = res;
        if (licencesImage) {
          this.imgList = [
            {
              uid: licencesImage,
              url: licencesImage,
              origin: licencesImage,
            },
          ];
        }
        if (logoImage) {
          this.companyLogoList = [
            {
              uid: logoImage,
              url: logoImage,
              origin: logoImage,
            },
          ];
        }
        if (wechatOffiCccountImage) {
          this.wechatOffiCccountImageList = [
            {
              uid: wechatOffiCccountImage,
              url: wechatOffiCccountImage,
              origin: wechatOffiCccountImage,
            },
          ];
        }
        if (images) {
          this.imagesList = images.map((item) => {
            return {
              uid: item,
              url: item,
              origin: item,
            };
          });
        }
        if (wechatImages) {
          this.wechatImages = wechatImages.map((item) => {
            return {
              uid: item,
              url: item,
              origin: item,
            };
          });
        }
        if (qqImages) {
          this.qqImages = qqImages.map((item) => {
            return {
              uid: item,
              url: item,
              origin: item,
            };
          });
        }
      });
    },
    getDict() {
      getDict({
        type: "autoPartsCity",
      }).then((res) => {
        this.autoPartsCitys = res;
      });
    },
    areaChange(dataObj) {
      this.ruleForm.provinceId = dataObj.areaIds[0];
      this.ruleForm.cityId = dataObj.areaIds[1];
      this.ruleForm.areaId = dataObj.areaIds[2];
    },
    changeStatus(newStatus) {
      this.$store.dispatch("changeAuthStatus", newStatus);
      this.$router.push("/login");
    },
    getImgFileList(fileList) {
      console.log(fileList);
      this.imgList = fileList;
    },
    getFileList(fileList, key) {
      this[key] = fileList;
    },
    remoteCategories(query) {
      getCategories({ value: query })
        .then((res) => {
          this.categories = res || [];
        })
        .catch(() => {
          this.categories = [];
        });
    },
    remoteBrands(query) {
      getBrands({ value: query })
        .then((res) => {
          this.brands = res || [];
        })
        .catch(() => {
          this.brands = [];
        });
    },
    remoteVehBrands(query) {
      getVehBrands({ value: query })
        .then((res) => {
          this.vehBrands = res || [];
        })
        .catch(() => {
          this.vehBrands = [];
        });
    },
    submitForm() {
      // const {phone1,phone2,phone3} = this.ruleForm
      // const reg = /(^0[1-9]\d{1,2}-?\d{7,8}$)|(\d{7,8})|(^1[3-9]\d{9}$)/
      // if(phone1 && !reg.test(phone1)) {
      //   this.message.warning('请输入zheng')
      // }
      this.$refs.ruleForm.validate((valid, err) => {
        if (valid) {
          updateCompany({
            ...this.ruleForm,
          }).then(() => {
            this.$message.success("修改资料成功!");
          });
        } else {
          let msg = "";
          for (const key in err) {
            if (Object.hasOwnProperty.call(err, key)) {
              const element = err[key];
              msg = element[0].message;
            }
          }
          this.$message.warning(msg);
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.company_info {
  padding: 0 @padding-size-main;
  background: #ffffff;
  margin-bottom: 70px;

  .footer_btn {
    position: fixed;
    width: 90%;
    background: #ffffff;
    box-shadow: 0px -1px 0px 0px rgba(217, 217, 217, 0.3);
    bottom: 8px;
    z-index: 99;

    .button-div {
      display: flex;
      justify-content: center;
      padding: 12px 0;
      border-top: 1px solid @border-color-base;
    }
  }
}
</style>
